<template>
  <div class="data-table-cell-event-value data-table-cell-group">
    <forms-input
      icon-post="copy"
      icon-post-interactive
      read-only
      :size="$pepper.Size.S"
      :value="content"
      @iconClick="onCopy"
    />
  </div>
</template>

<script>
export default {
  name: 'DataTableCellEventValue',

  props: {
    content: {},
  },

  methods: {
    onCopy() {
      let tempInput = document.createElement("input")
      tempInput.value = this.content || null
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand("copy")
      document.body.removeChild(tempInput)

      this.$notification({
        title: this.$t('sayl.copy_to_clipboard'), 
        message: this.$t('sayl.copy_to_clipboard_message'), 
        type: 'success'
      })
    },
  }
}
</script>
