<template>
  <main class="view-user-home layout-module-users">
    <div class="container user__container">
      <data-table
        :actions="actions"
        :columns="columns"
        :rows="rows"
      />
    </div>
  </main>
</template>

<script>
import DataTableCellInput from './data-table-cell-input'

export default {
  data() {
    return {
      values: []
    }
  },

  computed: {
    actions() {
      return [
        {
          label: 'Export to JSON',
          action: () => this.onExport('json'),
          mode: this.$pepper.Action.GLOBAL
        },
      ]
    },

    categories() {
      let ret = []

      this.values.forEach(v => {
        let f = ret.find(r => r.group === v.group)
        if(this.$basil.isNil(f)) {
          f = {
            group: v.group,
            keys: []
          }
          ret.push(f)
        }
        f.keys.push(v.key)
      })

      return ret.sort((a, b) => a.keys.length - b.keys.length)
    },

    rows() {
      let ret = []

      this.categories.forEach((categ) => {
        categ.keys.forEach(key => {
          ret.push({
            fqn: `${categ.group }.${key}`,
            data: [
              { content: categ.group, },
              {
                component: DataTableCellInput,
                content: key,
              }
            ]
          })
        })
      })
      return ret
    },

    columns() {
      return [
        {
          fqn: '12345',
          label: 'Group'
        },
        {
          fqn: '123454',
          label: 'Key'
        }
      ]
    }
  },

  methods: {
    onExport(format) {
      let ret = {}
      switch(format) {
        case 'json': 
          this.categories.forEach((categ) => {
            ret[categ.group] = {}
            categ.keys = categ.keys.sort()
            categ.keys.forEach(key => {
              ret[categ.group][key] = ""
            })
          })
          break;
      }

      let url = window.URL.createObjectURL(new Blob([JSON.stringify(ret)]))
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `missing-translation.${format}`)
      document.body.appendChild(link)
      link.click()
      // sessionStorage.removeItem('missing_translations')
      this.reset()
    },

    reset() {
      let missingTranslations = sessionStorage.getItem('missing_translations')
      this.values = this.$basil.isNil(missingTranslations) ? 
        [] : missingTranslations = JSON.parse(missingTranslations)
    }
  },

  mounted() {
    this.reset()
  },
}
</script>
